<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t('label_senders_and_recipients_book')}}</b-breadcrumb-item>
      </div>
    </div>

    <b-card no-body v-if="$can('edit', PREFIX+'.tab_details')">
      <b-card-header class="pb-50">
        <h5 >
          {{$t('label_senders_and_recipients_book')}}
          <!--{{ // $t('label_filters') }}-->
        </h5>
      </b-card-header>
      <b-card-body >
        <b-row>


          <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mb-2 d-flex align-items-center"
          >
            <b-button variant="outline-primary" v-if="$can('edit', PREFIX+'.tab_details')" :to="{ name: 'mail_add' }" class="mr-1 ">
              <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
              />
              <span>{{$t('label_add_mail')}}</span>
            </b-button>
            <b-button variant="outline-primary"@click="editedMember = false;$bvModal.show(PREFIX + '-modal')"  v-if="$can('edit', PREFIX+'.tab_details')" class="mr-1" >
              <feather-icon
                      icon="UserPlusIcon"
                      class="mr-50"
              />
              <span>{{$t('label_add_to_recipient_database')}}</span>
            </b-button>
            <!--<router-link v-if="$can('edit', MODULE_PREFIX+'.tab_details')" :to="{ name: 'mail_add' }" class="mr-1 btn btn-success">{{$t('label_add_mail')}}</router-link>-->
            <!--<b-button v-if="$can('edit', MODULE_PREFIX+'.tab_details')"  class="mr-1  btn-warning" >{{$t('label_add_to_recipient_database')}}</b-button>-->

          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
                  cols="12"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

          >

            <v-select

                    v-model="tableData.perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tableData.perPageOptions"
                    :clearable="false"
                    :searchable="false"
                    class="per-page-selector d-inline-block mr-1"
            >
              <template v-slot:no-options="option">
                {{$t('label_no_items')}}
              </template>
            </v-select>
            <v-select
                    style="max-width: 300px"
                    class="sort--select"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="filterData.member_type"
                    :options="memberTypes"
                    label="value"
                    :placeholder="$t('label_select_type')"
                    :reduce="val => val.value"

            >
              <template v-slot:option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.label) }}</span>
              </template>
              <template v-slot:selected-option="option">
                <!--<span :class="option.icon"></span>-->
                {{ $t(option.label) }}
              </template>
              <template v-slot:no-options="option">
                {{$t('label_no_items')}}
              </template>
            </v-select>
          </b-col>

          <!-- Search -->
          <b-col
                  cols="12"
                  md="5"
                  class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <b-form-input
                    v-model="tableData.searchQuery"
                    type="search"
                    debounce="500"
                    class="d-inline-block mr-1 "
                    :placeholder="$t('label_search')+'...'"
            />


          </b-col>
        </b-row>

      </div>

      <!--***********************************************************TABLE*********************************************-->

    <div class="px-2 table-container-wrap">
      <!--sticky-header-->
      <b-table
              ref="table"
              class="position-relative transited-table"
              :busy="isBusy"
              no-border-collapse
              :items="tableItems"
              responsive
              :fields="columnsDefs"
              primary-key="id"
              :sort-by.sync="tableData.sortBy"
              no-local-sorting
              :sort-desc.sync="tableData.sortDesc"
              show-empty
              :tbody-transition-props="{ name: 'flip-list'}"
              :empty-text="$t('label_no_table_data')"

      >

        <template #head()="data">
          <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
        </template>


        <template #cell(display_name)="data">

          <a v-if="data.item.display_name" target="_blank" :href="$base_url+'mail/downloadMailDoc/' + data.item.id"> <feather-icon icon="fileIcon"></feather-icon> </a>

        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
                  dropleft
                  boundary="window"
                  variant="link"
                  no-caret
                  @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                  @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                  :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>



            <b-dropdown-item disabled>
              <div class="divider">
                <div class="divider-text">{{ $t('label_action') }}</div>
              </div>
            </b-dropdown-item>

            <b-dropdown-item @click="editedMember = data.item;$bvModal.show(PREFIX+'-modal')">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(data.item.id)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
            </b-dropdown-item>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="tableTotal"
                    :per-page="tableData.perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </div>

    </b-card>

    <process-member-modal
            @item-added="refreshDataTable()"
            @item-edited="refreshDataTable()"
            :editedItem="editedMember"
    ></process-member-modal>

  </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import processMemberModal from './../mail/modals/processMemberModal'

    import { MAIL_PREFIX as MODULE_PREFIX, PARTICIPANT_PREFIX as PREFIX, memberTypes} from './moduleHelper'

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,

            vSelect,
            processMemberModal,
        },
        props:['tableType'],
        data() {
            return {

                MODULE_PREFIX,
                PREFIX,
                editedItem: false,
                isSidebarOpen: false,
                table_name: 'table-contacts',

                editedMember: false,

                selectedColumns:[],

                columnsDefs: [
                    // {label: 'id', key: 'id', sortable: true},
                    {label: 'label_name', key: 'name', sortable: false},
                    {label: 'label_address', key: 'address'},
                    {label: 'label_action', key: 'actions'},

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                filterData: {
                    member_type: '',

                },

                memberTypes

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.columnsDefs = this.columnsDefs.filter((el)=>{
                if((el.key == 'actions') && !this.$can('edit', 'mail.tab_details')){
                    return false;
                }
                return true;
            });
            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy=true;
                this.async('get', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s', {params: {
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        participant_type: this.filterData.member_type,

                    }}, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s/' + id, {params:{}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },

        },


    }
</script>

